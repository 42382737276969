import './Contacts.css';
import Button from '../../../button/Button';

const Contacts = () => {
    return (
        <section id='contacts'>
            <h2 className='fw300 text_color_primary_light'>Let's work together</h2>
            <div className='actions'>
                <Button to={'mailto:emanuele.frascella256@gmail.com'} text='get started' className='magnet' />
                <Button to={''} text='download CV' icon='download' className='magnet' />
            </div>
            <p className='fw300 text_color_secondary_light'>Hey there! Drop me a line, and let's team up to bring your wildest ideas to life!</p>
        </section>
    );
}

export default Contacts;