import './MaterialIcon.css';

interface MaterialIconProps {
    icon: string
}

const MaterialIcon = (props: MaterialIconProps) => {
    return <span className='material-symbols-outlined'>{props.icon}</span>
}

export default MaterialIcon;