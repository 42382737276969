import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer>
            <p className='fw400 text_color_primary_light text_small'>© 2023 Emanuele Frascella</p>
            <div className='social'>
                <Link to='https://www.facebook.com/e.frascella256/' target='_blank' className='magnet fw400 text_color_primary_light text_small'>Facebook</Link>
                <Link to='https://www.instagram.com/_emanuele_frascella/' target='_blank' className='magnet fw400 text_color_primary_light text_small'>Instagram</Link>
                <Link to='https://www.linkedin.com/in/efrascella256/' target='_blank' className='magnet fw400 text_color_primary_light text_small'>LinkedIn</Link>
                <Link to='https://github.com/manuto276' target='_blank' className='magnet fw400 text_color_primary_light text_small'>GitHub</Link>
            </div>
        </footer>
    );
}

export default Footer;