import './Experience.css';
import { useRef } from 'react';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import Button from '../../../button/Button';

const Experience = () => {
    return (
        <>
            <section id='experience'>
                <div className='title'>
                    <h2 className='fw300 text_color_primary_light'>
                        My Professional<br/>Journey
                    </h2>
                    <p className='fw300 text_color_secondary_light'>
                        I thrive in creative environments and value the power of teamwork. I cherish the energy and inspiration that come from working in creative settings, where collective ideas flourish, and collaborative efforts lead to remarkable outcomes.
                    </p>
                </div>
                <div className='journey_list'>
                    <Journey agency='la TERMICA srl' start='September 2021' end='Present' number='01' />
                </div>
            </section>
            <DownloadCV />
        </>
    );
}

interface JourneyProps {
    agency: string,
    start: string,
    end: string,
    number: string
}

const Journey = (props: JourneyProps) => {
    return (
        <div className='journey'>
            <h1 className='fw500 text_color_primary_light'>{props.number}</h1>
            <h3 className='fw200 text_color_primary_light'>{props.agency}</h3>
            <p className='fw400 text_color_primary_light'>{props.start} - {props.end}</p>
        </div>
    );
}

const DownloadCV = () => {
    const ref = useRef<HTMLDivElement>(null);
    
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['start end', 'end start']
    });

    const smoothScrollY = useSpring(scrollYProgress, {
        damping: 50,
        stiffness: 100
    });

    const layer1 = useTransform(smoothScrollY, value => `translate(-${50 + 50 * value}%, -50%)`);

    return (
        <section ref={ref} id='exp_download_cv'>
            <motion.div style={{transform: layer1}} className='sc_text fw500 text_color_primary_light uppercase'>check my cv</motion.div>
            <Button 
                className='magnet'
                text='download CV' 
                icon='download' 
                theme='light'
                to={''} />
        </section>
    );
}

export default Experience;