import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from './App';
import Home from './components/routes/home/Home';

const getViewportWidth = () => {
    document.getElementsByTagName('html')[0].setAttribute('style', `--viewport-width: ${window.innerWidth}`);
}

getViewportWidth();

window.addEventListener('resize', getViewportWidth);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />
            }
        ]
    }
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);