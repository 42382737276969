import { useEffect, useState } from 'react';
import ScrollIndicator from '../../scrollindicator/ScrollIndicator';
import About from './about/About';
import Contacts from './contacts/Contacts';
import Experience from './experience/Experience';
import Expertise from './expertise/Expertise';
import Landing from './landing/Landing';
import { useScroll } from 'framer-motion';

const Home = () => {
    const [isIndicatorVisible, setIndicatorVisible] = useState(false);
    const { scrollY } = useScroll();

    useEffect(() => {
        const onScrollHandler = (scroll: number) => {
            const landingSection = document.getElementById('landing')!;
            const contactsSection = document.getElementById('contacts')!;

            const y1 = landingSection.offsetHeight + landingSection.offsetTop;
            const y2 = contactsSection.offsetTop - window.innerHeight;

            if (scroll > y1 && scroll < y2)
                setIndicatorVisible(true);
            else
                setIndicatorVisible(false);
        }
        
        onScrollHandler(scrollY.get());
        scrollY.on('change', (value) => onScrollHandler(value));
    }, [])

    return (
        <>
            <Landing />
            <About />
            <Experience />
            <Expertise />
            <Contacts />
            <ScrollIndicator visible={isIndicatorVisible} />
        </>
    );
}

export default Home;