import { useEffect } from "react"
import { isDesktop } from "react-device-detect";

export const useMagneticCursor = () => {
    useEffect(() => {
        if (!isDesktop)
            return;
            
        const magnets = document.querySelectorAll('.magnet');

        magnets.forEach((magnet) => {
            const element = magnet as HTMLElement;

            element.addEventListener('mousemove', (e) => {
                const position = element.getBoundingClientRect();
                const x = e.clientX - position.left - position.width / 2;
                const y = e.clientY - position.top - position.height / 2;

                element.style.transform = `translate(${x * .2}px, ${y * .4}px)`;
                element.style.zIndex = '1';
            });

            element.addEventListener('mouseout', (e) => {
                element.style.transform = `translate(0px, 0px)`;
                element.style.zIndex = 'unset';
            });
        });
    });
}