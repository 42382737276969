import './About.css';
import { motion, useScroll, useSpring, useTransform } from 'framer-motion';
import { ReactNode, useRef } from "react";

const description = [
    'I am a software developer and front-end designer',
    'based in Taranto, Italy,',
    'with a burning desire',
    'to leave a meaningful mark on the world.',
    'I have a deep passion for creating',
    'innovative digital experiences',
    'that seamlessly blend functionality and aesthetics.'
]

const About = () => {
    const imgRef = useRef<HTMLDivElement>(null);

    const imageScrollYProgress = useScroll({
        target: imgRef,
        offset: ['start end', 'end start']
    }).scrollYProgress;

    const imageParallax = useTransform(imageScrollYProgress, value => `scale(1.3) translateY(calc(10vw * ${value}))`);

    return (
        <div id='about'>
            <div ref={imgRef} className='hero'>
                <motion.img src='/assets/beachphoto.png' style={{transform: imageParallax}} />
            </div>
            <h3 className='fw500 text_color_primary_light'>
                {description.map((value, index) => 
                    <TranslucentText key={index}>{value}</TranslucentText>
                )}
            </h3>
        </div>
    );
}

interface TranslucentTextProps {
    children?: ReactNode | undefined
}

const TranslucentText = (props: TranslucentTextProps) => {
    const ref = useRef<HTMLSpanElement>(null);

    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["end end", "start start"]
    });
    const smoothScroll = useSpring(scrollYProgress, {
        damping: 50,
        stiffness: 400
    });
    const mappedScroll = useTransform(smoothScroll, [0, .5, 1], [.3, 1, .3], {
        clamp: true
    });

    return <motion.span ref={ref} style={{opacity: mappedScroll}}>{props.children}</motion.span>
}

export default About;