import { useEffect, useState } from 'react';
import './Splashscreen.css';
import { useAnimate } from 'framer-motion';

interface SplashscreenProps {
    onAnimationEnd?: () => void
}

const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}  

const Splashscreen = (props: SplashscreenProps) => {
    const [isVisible, setVisible] = useState(true);
    const [scope, animate] = useAnimate();

    useEffect(() => {
        const animation = async () => {
            for (let i = 0; i < percentages.length; ++i) {
                await Promise.all([
                    animate('h1', {top: `calc(100% * ${1 - percentages[i] / 100})`}, {duration: .8, delay: i === 0 ? 0 : .5, ease: 'easeInOut'}),
                    animate('.digit0', {transform: `translateY(${-i * 100}%)`}, {duration: .65, delay: i === 0 ? 0 : .65, ease: 'easeInOut'}),
                    animate('.digit1', {transform: `translateY(${-i * 100}%)`}, {duration: .55, delay: i === 0 ? 0 : .6, ease: 'easeInOut'}),
                    animate('.digit2', {transform: `translateY(${-i * 100}%)`}, {duration: .45, delay: i === 0 ? 0 : .55, ease: 'easeInOut'}),
                ]);
            }

            await animate(scope.current, {opacity: 0}, {duration: .5, delay: 1.5, ease: 'easeInOut'});
        }

        animation().then(() => {
            if (props.onAnimationEnd)
                props.onAnimationEnd();
            setVisible(false);
        });
        
    // eslint-disable-next-line
    }, []);
 
    const percentages = [0, randomIntFromInterval(25, 45), randomIntFromInterval(65, 85), 100];

    if (isVisible)
        document.body.style.overflow = 'hidden';
    else 
        document.body.style.overflow = 'unset';

    return (
        isVisible ?
            <div ref={scope} id='splashscreen'>
                <h5 className='fw500 text_color_primary_light uppercase'>emanuele frascella<br/>portfolio ©</h5>
                <div className='percentage'>
                    <h1 className='fw500 text_color_primary_light'>
                        {percentages.map((number) => {
                            const digits = number.toString().split('');

                            return (
                                <span className='number'>
                                    {digits.map((digit, index) => {
                                        const actualIndex = digits.length - index - 1;

                                        return (
                                            <span className={`digit${actualIndex}`}>{digit}</span>
                                        );
                                    })}
                                </span>
                            );
                        })}
                    </h1>
                </div>
            </div>
        : null
    );
}

export default Splashscreen;