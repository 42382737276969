import { CSSProperties, MutableRefObject } from "react";

interface LogoProps {
    ref?: MutableRefObject<any>,
    id?: string,
    className?: string,
    style?: CSSProperties,
    color?: 'light' | 'dark'
}

const Logo = (props: LogoProps) => {
    const color = props.color === 'dark' ? '#ffffffde' : '#000000de';

    return (
        <div 
            ref={props.ref} 
            id={props.id} 
            className={props.className}
            style={props.style}>
            <svg width='100%' height='100%' viewBox='0 0 58 71'>
                <g stroke='none' strokeWidth='0.5' fill='none' fillRule='evenodd'>
                    <g transform='translate(1.000000, 1.000000)'>
                        <path className='shape' stroke={color} d='M43,57 L55,69 L12,69 L12,57 L43,57 Z M43,0 L55,12 L12,12 L12,29 L43,29 L55,41 L12,41 L12,57 L0,57 L0,0 L43,0 Z' fill={color}></path>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default Logo;