import MaterialIcon from '../materialicon/MaterialIcon';
import './Button.css';
import { Link } from 'react-router-dom';

interface ButtonProps {
    id?: string,
    className?: string,
    to: string,
    text: string,
    icon?: string,
    onClick?: () => void,
    theme?: 'light' | 'dark'
}

const Button = (props: ButtonProps) => {
    const theme = props.theme ?? 'light';

    return (
        <Link 
            id={props.id}
            className={`button ${theme} ${props.className}`}
            to={props.to} 
            onClick={props.onClick}>
            <div className='btn_background' />
            <div className='btn_container'>
                <div className={`fw500 text_color_primary_${theme} uppercase`}>{props.text}</div>
                <div className={`icn_container text_color_primary_${theme}`}>
                    <div className='icon1'>
                        <MaterialIcon icon={props.icon ?? 'arrow_forward'} />
                    </div>
                    <div className='icon2'>
                        <MaterialIcon icon={props.icon ?? 'arrow_forward'} />
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default Button;