import './Expertise.css';
import { useMotionValue, useScroll, useVelocity, useSpring, useTransform, wrap, useAnimationFrame, motion } from "framer-motion";
import { ReactNode } from "react";

const frameworks = ['HTML', 'CSS', 'JavaScript', 'TypeScript', 'Dart', 'C++', 'React.js', 'Flutter', 'Python', 'MySQL'];
const applications = ['Photoshop', 'Illustrator', 'Sketch', 'AutoCAD', 'SketchUp', 'Git', 'Visual Studio Code', 'MS Office Suite'];

const Expertise = () => {
    return (
        <section id='expertise'>
            <h2 className='fw300 text_color_primary_light'>
                My Expertise<br/>and Essential Toolkit
            </h2>
            <p className='fw300 text_color_secondary_light'>
                Over the years I have refined my skills as a creative developer. Here are some of the tools I’ve used:
            </p>
            <div className='px_container'>
                <ParallaxText baseVelocity={2}>
                    {frameworks.map((e, i) => {
                        return <span key={i}>{e}</span>
                    })}
                </ParallaxText>
                <ParallaxText baseVelocity={-2}>
                    {applications.map((e, i) => {
                        return <span key={i}>{e}</span>
                    })}
                </ParallaxText>
            </div>
        </section>
    );
}

interface ParallaxTextProps {
    children?: ReactNode | undefined,
    baseVelocity?: number
}

const ParallaxText = (props: ParallaxTextProps) => {
    const baseX = useMotionValue(0);
    const { scrollY } = useScroll();
    const scrollVelocity = useVelocity(scrollY);
    const smoothVelocity = useSpring(scrollVelocity, {
        damping: 50,
        stiffness: 400
    });
    const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
        clamp: true
    });

    const x = useTransform(baseX, (v) => `${wrap(0, -50, v)}%`);

    useAnimationFrame((time, delta) => {
        let moveBy = (props.baseVelocity ?? 5) * (delta / 1000);
        moveBy += moveBy * velocityFactor.get();
        baseX.set(baseX.get() + moveBy);
    });

    return (
        <div className='parallax'>
            <motion.h3 className='fw200 text_color_primary_light scroller' style={{ x }}>
                {props.children}
                {props.children}
            </motion.h3>
        </div>
    );
}

export default Expertise;