import { Outlet } from "react-router";
import Footer from "./components/footer/Footer";
import { useMagneticCursor } from "./hooks/magnetcursor";
import Header from "./components/header/Header";
import { useState } from "react";
import Splashscreen from "./components/splashscreen/Splashscreen";
import { motion } from "framer-motion";

const App = () => {
    const [isSplashVisible, setSplashVisible] = useState(true);

    useMagneticCursor();

    return (
        <>
            <Splashscreen onAnimationEnd={() => setSplashVisible(false)} />
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: isSplashVisible ? 0 : 1}}
                transition={{duration: .5, delay: .5, ease: 'easeInOut'}}>
                <Header />
                <Outlet />
                <Footer />
            </motion.div>
            <div className='noise' />
        </>
    );
}

export default App;