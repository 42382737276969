import { motion, useScroll, useTransform } from 'framer-motion';
import './ScrollIndicator.css';
import MaterialIcon from '../materialicon/MaterialIcon';

interface ScrollIndicatorProps {
    visible?: boolean
}

const ScrollIndicator = (props: ScrollIndicatorProps) => {
    const { scrollYProgress } = useScroll();
    const progress = useTransform(scrollYProgress, [0, 1], [0, 1]);

    const isVisible = props.visible ?? true;

    return (
        <div 
            className={`scrollindicator ${isVisible ? 'visible' : 'invisible'}`} 
            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            <motion.div 
                className='container'
                animate={{
                    y: ['0', '.5rem', '0']
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                }}>
                <svg viewBox='0 0 100 100'>
                    <circle
                        cx='50'
                        cy='50'
                        r='48'
                        strokeWidth='4px'
                        stroke='#0000004d'
                        pathLength='1'
                        fill='none' />
                    <motion.circle
                        cx='50'
                        cy='50'
                        r='48'
                        strokeWidth='4px'
                        stroke='#000000de'
                        fill='none'
                        style={{ pathLength: progress }} />
                </svg>
                <div className='icon text_color_secondary_light'>
                    <MaterialIcon icon='north' />
                </div>
            </motion.div>
        </div>
    );
}

export default ScrollIndicator;