import { useEffect, useState } from 'react';
import Logo from '../logo/Logo';
import './Header.css'

const Header = () => {
    return (
        <header>
            <Logo className='logo' />
            <p className='text_small fw500 text_color_primary_light'>
                taranto, it <Time />
            </p>
        </header>
    );
}

const Time = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDate(new Date());
          }, 1000)
      
          return () => clearInterval(intervalId);
    }, []);

    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    const offset = 2; // UTC of USA Eastern Time Zone is -05.00
    const italy = utc + (3600000 * offset);
    const italyTimeNow = new Date(italy);

    let hours = italyTimeNow.getHours();
    const minutes = italyTimeNow.getMinutes();
    const ampm = hours > 12 ? 'pm' : 'am';
    hours = hours > 12 ? hours - 12 : hours;

    const time = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm} ++`

    return (
        <span>{time}</span>
    );
}

export default Header;